.card_main{
    height: 100%;
    width: 100%;
    padding: 20px;
}
.card_main h3{
    margin-bottom: 10px;
    font-size: 0.9em;
}

.card_content{
    position: relative;
    background-color: whitesmoke;
    height: 110%;
    width: 95%;
    margin: auto;
    border-radius: 40px;
    border: 1px solid black;
}

.card_content p{
    margin-top: 10px;
    padding-left: 40px;
    font-size: 0.8em;
}
.card_table{
    padding: 0px 20px;
    width: 100%;
    height: 70%;
    text-align: center;
}


.table-container {
    height: 89%; /* Ajusta la altura según tus necesidades */
    overflow-y: auto;
    border-radius: 30px;
    position: relative;

    /* Scrollbar personalizada para Firefox */
    scrollbar-width: thin; /* Define un scrollbar más delgado */
    scrollbar-color: #a0a0a0 #f1f1f1; /* Color de la barra y el fondo */
}

/* Scrollbar personalizada para Chrome, Edge y Safari */
.table-container::-webkit-scrollbar {
    width: 8px; /* Ancho de la scrollbar */
    height: 8px; /* Alto de la scrollbar horizontal */
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Color del scroll */
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* Color al pasar el ratón */
}

.table-container::-webkit-scrollbar-track {
    background: transparent; /* Fondo de la pista */
}
.div_button_Card {
    position: absolute;
    bottom: 0; /* Se coloca en la parte inferior */
    left: 50%;
    transform: translateX(-50%);
    display: flex; /* Hacer que el contenedor use flexbox */
    justify-content: center; /* Centrar horizontalmente */
    align-items: center; /* Centrar verticalmente */
    height: 20%; /* Asegúrate de que la altura sea adecuada */
}

.button_Card {
    background-color: #e5f301FF;
    color: black;
    border-radius: 40px;
    font-weight: bold;
    text-decoration: none;
    padding: 15px 20px; /* Ajustar el padding para que el contenido encaje bien */
    white-space: nowrap; /* Evitar que el texto se divida en varias líneas */
    box-sizing: border-box; /* Incluir padding y borde en el tamaño total del elemento */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
  }
.no-scrollbar {
    scrollbar-width: none; /* Firefox */
}
.login-container{
    background-color: black;
    height: 100vh;
    width: 100%;
    display: flex;
}
.form_login{
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 600px;
    height: 800px;
    margin: auto;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: white;
}
.form_login img{
    height: 300px;
    width: 300px;
    margin-bottom: 50px;
}

.form_login form{
    display: flex;
    flex-direction: column;
    
}

.form_login form button:hover{
    background-color: orange;
}
.form_login .change_passw_button{
    width: 200px;
    height: 90px;
    text-align: center;
}
.form_login .change_passw_button:hover{
    background-color: orange;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spin-slow {
    animation: spin 3s linear infinite; /* 3 segundos para un giro completo */
  }
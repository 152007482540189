/* RiesgosForm.css */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9990;
}

.popup {
    height: 70%;
    width: 50%;
    background: white;
    border-radius: 30px;
    position: relative;
    width: 90%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.popup-close {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: red;
}

.popup form{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
}
.popup form label{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup form label p{
    margin-bottom: 20px;
    text-align: center;
}
.popup form button{
    background-color: #e5f301FF;
    border: none;
    border-radius: 30px;
    height: 60px;
    width: 180px;
    font-weight: bold;
    font-size: 1.1em;
    cursor: pointer;
}
.delete{
    background-color: rgba(255, 0, 0, 0.507) !important;
}
.delete:hover{
    background-color: rgba(255, 0, 0, 0.692) !important;
}
.popup form button:hover{
    background-color: rgb(255, 196, 0);
}
.popup form button:disabled:hover {
    background-color: #e5f301FF;; /* Restablece el color o define uno específico */
    cursor: not-allowed; /* Asegúrate de que el cursor siga siendo not-allowed */
}

.popup form .number_form_name{
    width: 50%;
    height: 40px;
    border-radius: 10px;
    padding:0px 4px;
}

.description_form{
    height: 80px;
    border-radius: 10px;
    padding:0px 5px;
}

.popup form .calor_inherente{
    width: 50%;
    height: 40px;
    border-radius: 10px;
    padding:0px 4px;
}
.popup form .email{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    padding:0px 4px;
}

.popup form input:focus {
    outline: none;
    box-shadow: none;
  }

.form_control{
    padding-top: 1em;
    height: 90%;
    width: 100%;
}
.riesgos_div{
    width: 100%;
    position: relative;
}

@media screen and (max-height: 750px) {
    .riesgos_div{
        width: 100%;
        height: 70%;
    }
    .div_pather{
        height: 50%;
        font-size: 0.8em;
    }
    .periodicity_label{
        font-size: 0.7em;
    }
    .button_control_submit{
        font-size: 0.9em !important;
        height: 50px !important;
        width: 200px !important;
    }
    .h4_control_form {
        font-size: 0.8em;
    }
}

.control_riesgos {
    margin: 0px auto;
    border: 1px solid gray;
    border-radius: 30px;
    width: 82%;
    height: 350px;
    overflow-y: scroll;
    padding: 10px;
  }


  .control_disabled {
    border: 1px solid gray;
    border-radius: 30px;
    width: 100%;
    height: 150px;
    overflow-y: scroll;
    position: relative; /* Para que el overlay se ajuste al contenedor */
}

.control_disabled::-webkit-scrollbar {
    display: none; /* Oculta el scrollbar en Chrome, Safari y Edge */
}
.overlay_control {
    position: sticky; /* Permite que el overlay se quede en su lugar */
    top: 0;
    left: 0;
    width: 100%;
    min-height: 150px; /* Ajusta el tamaño mínimo del overlay para cubrir todo el contenedor */
    background-color: rgba(0, 0, 0, 0.2); /* Color gris con opacidad */
    z-index: 1; /* Asegúrate de que esté encima del contenido */
    pointer-events: none; /* Para que no interfiera con el scroll */
}
  
  /* Ocultar barra de scroll en WebKit */
  .control_riesgos::-webkit-scrollbar {
    display: none; /* Oculta completamente la barra de desplazamiento en WebKit */
  }
  
  /* Para Firefox */
  .control_riesgos {
    scrollbar-width: none; /* Oculta la barra de desplazamiento en Firefox */
  }

.control_riesgos div{
    display: flex;
    width: 80%;
    margin: 10px auto;
}

.name_control{
    height: 40px;
    border-radius: 30px;
    padding: 20px;
}  
.riesgo-item {
    height: 50px;
    width: 220px;
    cursor: pointer;
    padding: 10px 20px;

    border: 1px solid #ccc;
    border-radius: 30px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.riesgo-item p{
    font-size: 0.9em;
    margin: auto;
    color: rgba(0, 0, 0, 0.801);
    width: 70%;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
  
.riesgo-item.selected {
    background-color: #fffccc; /* Cambia el color de fondo cuando está seleccionado */
}

.popup-contact{
    height: 400px;
    width: 30%;
    background-color: white;
    border-radius: 30px;
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.popup-contact p{
    font-size: 1.4em;
    text-align: center;
    font-weight: bold;
}
.react-datepicker__navigation.react-datepicker__navigation--next,
.react-datepicker__navigation.react-datepicker__navigation--previous {
  width: 50px;  /* Cambiar el ancho a un tamaño más pequeño */
  height: 30px; /* Cambiar la altura a un tamaño más pequeño */
  background-color: #e5f301FF; /* Mantener el fondo amarillo */
  border-radius: 20px; /* Darle una forma circular si es necesario */
  box-shadow: none; /* Eliminar cualquier sombra si existe */
  outline: none; /* Eliminar el contorno de enfoque si existe */
  display: flex; /* Asegurar que el contenido esté centrado */
  align-items: center; /* Centrar el contenido verticalmente */
  justify-content: center; /* Centrar el contenido horizontalmente */
}

/* Estilo para los iconos de navegación dentro de los botones */
react-datepicker__navigation-icon,
react-datepicker__navigation-icon--next{
  width: 10px; /* Ajustar el tamaño del icono */
  height: 10px; /* Ajustar el tamaño del icono */
  border-color: black; /* Cambiar el color del icono de navegación (la flecha) */
}

/* Asegurarse de que el color de fondo al pasar el ratón sea adecuado */
.react-datepicker__navigation--next:hover,
.react-datepicker__navigation--previous:hover {
  background-color: #e0a800; /* Un tono más oscuro de amarillo al pasar el ratón */
}

.popup_img{
    height: 90%;
    width: 60%;
    background-color: white;
    border-radius: 30px;
    position: relative;
}
.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.upload-content h4{
    margin-top: 40px;
    font-size: 1.5em;
}
.upload-content .upload_content_div{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdf-preview {
    width: 100%;
    height: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pdf-preview iframe{
    height: 80%;
    width: 80%;
    border-radius: 30px;
    border: none;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 40px;

}
.popup-close{
    background-color: white;
    height: 40px;
    width: 40px;
}

.popup-close svg{
    background-color: white !important; 
}

.popup-button {
    background-color: #e5f301FF;
    border: none;
    border-radius: 30px;
    height: 60px;
    width: 140px;
    font-weight: bold;
    cursor: pointer;
}

.popup-button:hover {
    background-color: rgb(255, 196, 0);
}

.img_s3{
    height: 70%;
    width: 80%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 80px;
}
.img_s3 iframe{
    height: 100%;
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%,#ffa516);
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation: l13 1s infinite linear;
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }
/* src/components/Layout.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Helvetica, Arial, sans-serif;
}
html {
    padding: 0px;
}
body {
    padding: 0px;
}
/* Estilos para el contenedor principal del layout */
.layout-container {
    display: flex;
}

/* Estilos para el contenido principal */
.content {
    flex: 1;
}

.content-padding {
    padding: 20px;
    height: calc(100vh - 140px);
}

/* Estilos para el sidebar */
.logo_sidebar {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-bottom: 60px;
}
.sidebar {
    position: relative;
    border-right: 1px solid grey;
    margin: 0px;
    margin-right: 40px;
    width: 140px;
    height: 100vh;
    padding: 10% 20px;
    text-align: center;
}
.sidebar .link_activate{
    font-weight: bold;
}
.sidebar h1 {
    margin-bottom: 40px;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    
}

.sidebar li {
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 20px;
}

.sidebar a {
    color: black;
    text-decoration: none;
}


.signout_button {
    background-color: #e5f301FF;
    font-size: 0.8em;
    width: 80%;
    padding: 10px 10px;
    border-radius: 30px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.3s ease; /* Añadir una transición para un efecto suave */
  }
  
  .signout_button:hover {
    background-color: orange;
  }


/* Estilos para el navbar */
.navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 60px; /* Valor base (para pantallas más pequeñas) */
}

.search-container {
    position: relative;
    width: 50%;
    height: 65%;
    margin-right: 40px;
}


.navbar .user-info {
    display: flex;
    align-items: center;
}

.navbar .user-info div {
    margin-left: 10px;
    font-weight: bold;
    display: flex;

}

.navbar .user-info img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: gray;
    object-fit: cover; 
    object-position: center;  
}

@media (min-width: 1536px) {
    .navbar {
        height: 80px; /* Aplica este valor solo en pantallas >= 2xl */
    }
}




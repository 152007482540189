
.home_main{
    height: 100%;
}
.home_main .admin_home{
    height: 100%;
}
.home_main .gestor_home{
    height: 60%;
}
.responsable_home{
    height: 89%;
    width: 100%;
}
.responsable_main{
    height: 70vh;
    width: 90%;
}
.length-responsable{
    height: 10%;
}

.card_table {
    border-collapse: collapse;
    width: 100%;
}

.card_table thead {
    position: sticky;
    top: 0;
    background-color: whitesmoke; /* O el color que prefieras */
    z-index: 1;
}
.card_table .no_main {
    position: sticky; /* Fija la cabecera */
    top: 0; /* Posiciona en la parte superior */
    z-index: 2; /* Asegúrate de que el encabezado esté encima de otras partes */
}
.table-row th {
    text-align: center;
    border-top: none;
    font-weight: bold;
    padding: 8px; /* Añade padding si es necesario */
    height: 60px;
    font-size: 0.8em;
    padding-left: 20px;
}

.table-row td {
    height: 60px; /* Altura fija para los th y td */
    border-top: 1px solid black;
    font-weight: normal;
    font-size: 0.9em;
    text-align: center;
    padding: 8px; /* Añade padding si es necesario */
    padding-left: 20px;
}


.table-row svg{
    background-color: rgba(128, 128, 128, 0.555);
    border-radius: 100px;
    padding: 5px;
    cursor: pointer;
}

.archive_responsable {
    padding: 0px 20px;
    width: 200px;
}

.archive_responsable .archive {
    cursor: pointer;
    background-color: #e5f301FF;
    border-radius: 20px;
    width: 150px; /* Ajusta el ancho de la caja */
    height: 35px;
    display: flex;
    align-items: center;
}
.archive_responsable p{
    text-align: center;
}
.archive_responsable .archive p {
    margin: 10px;
    display: block; /* Cambiar de flex a block */
    max-width: 100%; /* Asegura que el ancho máximo sea el del contenedor padre */
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    overflow: hidden; /* Asegura que el contenido desbordante no se muestre */
    text-overflow: ellipsis; /* Aplica los puntos suspensivos al final del texto */
}

.archive_responsable .archive_button{
    background-color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 20px;
    cursor: pointer;
    margin: auto;
}
.archive_responsable .archive_button:hover{
    background-color: orange;
}

.home_hub{
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Divide en 4 columnas iguales */
}
.close-icon {
    z-index: 9989;
    position: absolute;
    top: 10%;
    right: 10%;
    cursor: pointer;
}

.big_card{
    padding: 20px;
    width: 100vh;
    height: 40vh;
}

.small_card{
    padding: 20px;
    width: 60vh;
    height: 40vh;
}

/* ADMIN */
.bussines_box{
    height: 100%;
}
.bussines_div{
    height: 90%;
}
.admin_boxes {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: start;
    height: 90%;
    width: 100%;
    padding: 0px 80px;
}
  
.bussiness_boxes {
    cursor: pointer;
    height: 180px; /* Altura base para pantallas pequeñas */
    width: 80%;
    padding: 10px;
    margin: 20px;
    background-color: rgba(128, 128, 128, 0.26);
    border: 1px solid #ccc;
    border-radius: 30px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.bussiness_boxes h3 {
    font-size: 0.8rem; /* Tamaño base de la fuente */
    margin-top: 10px;
}

.bussiness_boxes:hover {
    background-color: rgba(184, 184, 184, 0.26);
}

.bussiness_boxes div{
    font-size: 0.9em;
}
/* Estilos para pantallas >= 2xl */
@media (min-width: 1536px) {
    .bussiness_boxes {
        height: 260px; /* Altura aumentada para pantallas grandes */
    }

    .bussiness_boxes h3 {
        font-size: 1.25rem; /* Tamaño de fuente más grande */
    }
    .bussiness_boxes div{
        font-size: 1.0em;
    }
}

.main_action{
    height: 100%;
}

.riesgos_controles{
    display: flex;
    justify-content: center;
    align-items: center;
}
.riesgos_controles div{
    background-color: #e5f301FF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    height: 40px;
    width: 50px;
    padding: 10px;
    margin: 10px;
    
}
@media (max-width: 1280px) {
    .upper_box{
        border: 1px solid black;
        display: flex;
        flex-direction: row;
        width: 300px;
        height: 60px;
        padding: 0px;
        border-radius: 30px;
        margin: 20px 0px;
    }
    
    .upper_box .text{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
    }
    .upper_box .number{
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5em;
        font-weight: bold;
    }
    .upper_box{
        height: 60px;
    }
    .close-icon{
        height: 40px;
        width: auto;
    }
    .big_card{
        padding-top: 10px;
    }
    .small_card{
        padding-top: 10px;
    }
    .big_card h3{
        font-size: 0.8em;
    }
    .big_card th{
        font-size: 0.6em;

    }
    .big_card td{
        font-size: 0.6em; 
    }

    .small_card th{
        font-size: 0.6em;

    }
    .small_card td{
        font-size: 0.6em; 
    }

    .small_card h3{
        font-size: 0.8em;
    }

    .bussiness_boxes {
        height: 150px;
        font-size: 0.8em;
    }
    .admin_boxes {
        gap: 0px;
    }
    .sidebar li{
        font-size: 0.7em;
    }
    .navbar{
        height: 50px;
    }
    .close-icon {
        z-index: 9989;
        position: absolute;
        top: 7%;
        right: 10%;
        cursor: pointer;
    }
    .user-info{
        height: 35px;
    }
    .user-info div{
        height: 35px;
        width: 35px;
    }
    .card_table{
        font-size: 0.7em;
    }
    .content-padding{
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (min-width: 1281px) {
    .upper_box{
        border: 1px solid black;
        display: flex;
        flex-direction: row;
        width: 350px;
        height: 80px;
        padding: 0px;
        border-radius: 30px;
        margin: 20px 0px;
    }

    .upper_box.auditoria{
        width: 500px;
    }
    
    .upper_box .text{
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
    }
    .upper_box .number{
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6em;
        font-weight: bold;
    }
    .paperbin svg{
        height: 3em !important;
        width: 3em !important;
    }
    .sidebar li{
        font-size: 0.8em;
    }

}
.total_add{
    display: flex;
    align-items: center;    
    justify-content: space-between;
}
.total_add svg{
    margin-right: 200px;
    background-color: #e5f301FF;
    border-radius: 100px;
    padding: 10px;
    height: 40px;
    width: 40px;
    cursor: pointer;
}

.paperbin{
    display: flex;
    justify-content: center;
    align-items: center;
}
.paperbin svg{
    margin-left: 2em;
    height: 4em;
    width: 4em;
    padding: 1em;
    border-radius: 10px;
    background-color: rgba(128, 128, 128, 0.452);
}
.paperbin svg.selected{
    margin-left: 2em;
    height: 4em;
    width: 4em;
    padding: 1em;
    border-radius: 10px;
    color: white;
    background-color: rgb(184, 46, 46);
}

.card_option .table-container {
    background-color: whitesmoke;
    height: 100%;
    padding: 0;
    border: 1px solid black;
    border-radius: 30px;
    box-shadow: 1px 1px 1px grey;
    
    /* Oculta el scrollbar en navegadores modernos */
    overflow: scroll;
}

/* Opcional: elimina el scrollbar en navegadores basados en Webkit (Chrome, Safari, Edge) */
.card_option .table-container::-webkit-scrollbar {
    display: none;
}

/* Opcional: elimina el scrollbar en Firefox */
.card_option .table-container {
    scrollbar-width: none; /* Firefox */
}

.skeleton {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
  }
  
  /* Animación para el efecto de esqueleto */
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
}

.skeleton-text {
    width: 100%; /* Ajusta esto para simular el ancho de una línea de texto */
    height: 16px; /* Ajusta la altura según el tamaño del texto */
  }

.td-truncate{
    width: 10em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} 